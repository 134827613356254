import { useRef, useState } from "react";
import PropTypes from "prop-types";

import { Cropper } from "react-cropper";

import "cropperjs/dist/cropper.css";
import "./Cropper.css";
import styles from "./AvatarCropper.module.css";

const AvatarCropper = ({ onCrop, setActiveBtnPicture }) => {
  const cropperRef = useRef(null);
  const [image, setImage] = useState(null);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const cropImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImage = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      onCrop(croppedImage);
    }
  };

  return (
    <form className={styles.formUpload}>
      <input type="file" id="fileInput" onChange={onChange} className={styles.fileInput} />
      {image && (
        <Cropper
          src={image}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          aspectRatio={1}
          preview=".img-preview"
          guides={false}
          ref={cropperRef}
          viewMode={1}
          className="cropper-container"
        />
      )}
      <button
        type="submit"
        className={`${styles.btn} ${styles.submitUploadBtn} `}
        disabled={!image}
        onClick={() => {
          cropImage();
          setActiveBtnPicture(null);
        }}
        id="submitUploadBtn"
      >
        Upload
      </button>
    </form>
  );
};

AvatarCropper.propTypes = {
  onCrop: PropTypes.func,
};

AvatarCropper.defaultProps = {
  onCrop: () => {},
};

export default AvatarCropper;
