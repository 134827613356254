import React, { useState, useEffect } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from "react-redux";
import AvatarSelectorModal from "./sub_windows/AvatarSelectorModal";
import * as selector from "../../../selectors";
import styles from "../../createSynthetic.module.css";
import PreLoading from "../../../../../components/pre_loading/PreLoading";
import { loadAllAvatar, loadAllSharedAvatars } from "../../../userSyntheticSlice";
import UploadAvatar from "./sub_windows/UploadAvatar";

function AddAvatar({
  checkDonePicture,
  OnClickBtn,
  activeImage,
  setActiveImage,
  setActiveBtnPicture,
  activeBtnPicture,
  stateOfWindow,
  setWindowState,
  setErrorMessage,
  setOpenErrorWindow,
  setSelectedAvatar,
  setCheckDonePicture,
  setNewSynthetic,
  getAvatarById,
}) {
  const dispatch = useDispatch();
  const isLoadingUploadAvatar = useSelector(selector.selectIsLoadingUploadAvatar);
  const avatars = useSelector(selector.selectAllAvatar);
  const sharedAvatars = useSelector(selector.selectAllSharedAvatar);
  const [showDropdownPicture, setShowDropdownPicture] = useState(false);
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    if (stateOfWindow === "cancel") {
      setShowDropdownPicture(false);
      setWindowState("active");
      setActiveImage(null);
    }
  }, [stateOfWindow]);

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleDeleteAvatar = async () => {
    try {
      // await dispatch(deleteAvatar(avatarId));
      dispatch(loadAllAvatar());
    } catch (error) {
      setErrorMessage("Failed to delete avatar. Please try again.");
      setOpenErrorWindow(true);
    }
  };

  const handleSelectImage = () => {
    const selectedAvatarData =
      avatars.find((ava) => ava.id === activeImage) || sharedAvatars.find((ava) => ava.id === activeImage);

    if (selectedAvatarData) {
      setNewSynthetic((prevState) => ({
        ...prevState,
        avatarId: activeImage,
      }));

      setSelectedAvatar(`data:image/*;base64,${selectedAvatarData.binaryData}`);

      setOpenModal(null);

      setActiveBtnPicture(null);
      setCheckDonePicture(true);
    }
  };

  useEffect(() => {
    dispatch(loadAllAvatar());
    dispatch(loadAllSharedAvatars());
  }, [dispatch]);

  return (
    <div>
      <button
        className={styles.btnDropdown}
        id="btnDropdownPicture"
        type="button"
        onClick={() => setShowDropdownPicture(!showDropdownPicture)}
      >
        <span className={styles.textIconInButton}>
          {checkDonePicture != null &&
            (checkDonePicture ? (
              <DoneIcon style={{ color: "green" }} />
            ) : (
              <WarningAmberIcon style={{ color: "#EC4A0A" }} />
            ))}
          1. Add an avatar
        </span>
        {showDropdownPicture ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {showDropdownPicture && (
        <div className={`${styles.dropdownElement} ${styles.picture}`}>
          <button
            className={`${styles.btn} ${activeBtnPicture === "btnPictureUpload" ? styles.activeBtnByCreate : ""}`}
            id="btnPictureUpload"
            type="button"
            onClick={() => {
              OnClickBtn("pictureSection", "btnPictureUpload");
              handleOpenModal("uploadModal");
            }}
          >
            <UploadIcon />
            Upload new avatar
          </button>
          {openModal === "uploadModal" && (
            <div className={styles.modalOverlay}>
              <div className={styles.modalContent}>
                {isLoadingUploadAvatar ? (
                  <PreLoading />
                ) : (
                  <UploadAvatar
                    setOpenModal={setOpenModal}
                    setActiveBtnPicture={setActiveBtnPicture}
                    setCheckDonePicture={setCheckDonePicture}
                    setOpenErrorWindow={setOpenErrorWindow}
                    setErrorMessage={setErrorMessage}
                    setSelectedAvatar={setSelectedAvatar}
                    setNewSynthetic={setNewSynthetic}
                    setActiveImage={setActiveImage}
                  />
                )}
              </div>
            </div>
          )}
          <button
            className={`${styles.btn} ${activeBtnPicture === "btnPictureSelect" ? styles.activeBtnByCreate : ""}`}
            id="btnPictureSelect"
            type="button"
            onClick={() => {
              OnClickBtn("pictureSection", "btnPictureSelect");
              handleOpenModal("userAvatars");
            }}
          >
            <DownloadDoneIcon />
            My avatars
          </button>
          <button
            className={`${styles.btn} ${activeBtnPicture === "btnPictureSelect" ? styles.activeBtnByCreate : ""}`}
            id="btnPictureSelect"
            type="button"
            onClick={() => {
              OnClickBtn("pictureSection", "btnPictureSelect");
              handleOpenModal("standardAvatars");
            }}
          >
            <DownloadDoneIcon />
            Standard
          </button>
          {activeImage != null ? (
            <div>
              <img className={styles.avaByConfirm} src={`data:image/*;base64,${getAvatarById()}`} alt="confirmAva" />
            </div>
          ) : (
            <div className={styles.avaEmpty}>No avatar is selected</div>
          )}
          {openModal === "userAvatars" && (
            <AvatarSelectorModal
              title="Select your avatar"
              avatars={avatars}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
              handleSelectImage={handleSelectImage}
              handleDeleteAvatar={handleDeleteAvatar}
              closeModal={() => {
                setOpenModal(null);
                setActiveBtnPicture(null);
              }}
            />
          )}
          {openModal === "standardAvatars" && (
            <AvatarSelectorModal
              title="Select an existing avatar"
              avatars={sharedAvatars}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
              handleSelectImage={handleSelectImage}
              handleDeleteAvatar={handleDeleteAvatar}
              closeModal={() => {
                setOpenModal(null);
                setActiveBtnPicture(null);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AddAvatar;
