// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editSynthetic,
  getSyntheticById,
  loadAllAvatar,
  loadAllSharedAvatars,
} from "../user_synthetic/userSyntheticSlice";
import * as selector from "../user_synthetic/selectors";
import styles from "../user_synthetic/details_synthetic/general/detailsSynthetic.module.css";
import style from "./editPage.module.css";
import TextSynthetic from "../../components/text_synthetic/TextSynthetic";
import Button from "../../components/button/Button";
import buttonStyle from "../../components/button/button.module.css";
import ConfirmCreateSynWindow from "../../components/popup_windows/confirm_create_syn_window/ConfirmCreateSynWindow";
import PreLoading from "../../components/pre_loading/PreLoading";

function EditPage() {
  const [hoveredButton, setHoveredButton] = useState(null);
  const avatars = useSelector(selector.selectAllAvatar);
  const sharedAvatars = useSelector(selector.selectAllSharedAvatar);
  const isLoadingCreateSyn = useSelector(selector.selectIsLoadingCreateSyn);
  const [activeButton, setActiveButton] = useState("button1");
  const [hasChanges, setHasChanges] = useState(false);
  const [newSynthetic, setNewSynthetic] = useState({
    text: "",
  });
  const [editSyn, setEditSyn] = useState(null);
  const [textOrigin, setTextOrigin] = useState(" ");
  const [nameOrigin, setNameOrigin] = useState(" ");
  const [text, setText] = useState(" ");
  const [successfulEdit, setEditSuccessful] = useState(false);
  const [textChecked, setTextChecked] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { synId } = useParams();

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const getAvatarById = () => {
    const selectedAvatarData =
      avatars.find((ava) => ava.id === newSynthetic.avatarId) ||
      sharedAvatars.find((ava) => ava.id === newSynthetic.avatarId);
    return selectedAvatarData.binaryData;
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClickBtn = (currSection, buttonId) => {};

  const handleSave = () => {
    setConfirm(true);
  };

  const handleCreate = useCallback(async () => {
    await dispatch(editSynthetic({ newSynthetic }))
      .unwrap()
      .then((result) => {
        setEditSyn(result.id);
      });
    setEditSuccessful(true);
  }, [dispatch, newSynthetic]);

  const handleCreateConfirmWindow = () => {
    setConfirm(!confirm);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleCancelInConf = () => {
    setText(textOrigin);
    setNewSynthetic((prevNewSynthetic) => ({
      ...prevNewSynthetic,
      name: nameOrigin,
    }));
  };

  useEffect(() => {
    const loadStyleSettings = () => {
      dispatch(getSyntheticById(synId))
        .unwrap()
        .then((result) => {
          setNewSynthetic(result);
          setText(result.text);
          setTextOrigin(result.text);
          setNameOrigin(result.name);
        });
    };

    loadStyleSettings();
  }, [dispatch, synId]);

  useEffect(() => {
    if (text !== "" && text !== textOrigin && textChecked) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [text, textOrigin, textChecked]);

  useEffect(() => {
    dispatch(loadAllAvatar());
    dispatch(loadAllSharedAvatars());
  }, [dispatch]);

  useEffect(() => {
    if (successfulEdit) {
      navigate(`/account/mySynthetic/${editSyn}`);
    }
  }, [navigate, successfulEdit, editSyn]);

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
            id="backToMySyntheticButton"
          >
            <div className={styles.buttonBackContainer}>
              <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
              <div className={styles.buttonBackTextContainer}>
                <span>My Synthetic</span>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/${synId}`, "button1")}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            id="generalButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>General</span>
              </div>
              {activeButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      {isLoadingCreateSyn ? (
        <PreLoading />
      ) : (
        <div className={style.editStyleCard}>
          <div className={style.editSpeechPart}>
            <h2> Edit speech of synthetic: {newSynthetic.name}</h2>

            <div className={hasChanges ? style.dropdownElementChanged : style.dropdownElementNotChanged}>
              <TextSynthetic
                text={text}
                setTextChecked={setTextChecked}
                setNewSynthetic={setNewSynthetic}
                newSynthetic={newSynthetic}
                handleClickBtn={handleClickBtn}
                setText={setText}
              />
            </div>

            <div className={style.buttonContainer}>
              <Button
                buttonName="Save"
                buttonType="submit"
                onClick={handleSave}
                className={hasChanges ? buttonStyle.buttonSave : buttonStyle.buttonDisabled}
                disabled={!hasChanges}
              />
              <Button
                buttonName="Cancel"
                buttonType="button"
                onClick={handleCancel}
                className={buttonStyle.buttonCancel}
              />
            </div>
          </div>
          {confirm && (
            <ConfirmCreateSynWindow
              text={text}
              newSynthetic={newSynthetic}
              setConfirmWindowIsActive={setConfirm}
              handleCancel={handleCancelInConf}
              handleCreate={handleCreate}
              getAvatarById={getAvatarById}
              handleCreateConfirmWindow={handleCreateConfirmWindow}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default EditPage;
