import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import Logged from "../../../components/logged/Logged";

export default function PublicRoute() {
  if (Cookies.get("refreshToken")) {
    return <Logged />;
  }

  return <Outlet />;
}
