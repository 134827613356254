import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { selectAuth } from "./features/auth/selectors";
import Terms from "./components/terms/Terms";
import Privacy from "./components/privacy/Privacy";
import Login from "./features/auth/Login";
import RecoveryEmail from "./features/auth/RecoveryEmail";
import SignUp from "./features/auth/SignUp";
import Confirmation from "./features/auth/Confirmation";
import Home from "./components/home_page/Home";
import Layout from "./components/layout/Layout";
import AccountSynthetic from "./features/user_synthetic/AccountSynthetic";
import CreateSynthetic from "./features/user_synthetic/create_synthetic/CreateSynthetic";
import Pricing from "./features/balance/Pricing";
import CompanyInfo from "./features/company_info/CompanyInfo";
import ContactInfo from "./features/contact_info/ContactInfo";
import PrivateDate from "./features/private_date/PrivateDate";
import PaymentHistory from "./features/payment_history/PaymentHistory";
import PaymentInfo from "./features/payment_info/PaymentInfo";
import NotFound from "./components/404/NotFound";
import PreLoading from "./components/pre_loading/PreLoading";
import DetailsSynthetic from "./features/user_synthetic/details_synthetic/general/DetailsSynthetic";
import EditStyle from "./features/user_synthetic/details_synthetic/edit_style/EditStyle";
import checkAuth from "./features/auth/authService";
import AuthRoute from "./features/auth/routes/AuthRoute";
import PublicRoute from "./features/auth/routes/PublicRoute";
import FAQPage from "./components/faq_page/FAQPage";
import ContactUs from "./features/contact_us/ContactUs";
import PricePage from "./components/price_page/PricePage";
import WorkPage from "./components/work_page/WorkPage";
import FlagPage from "./components/flag_page/FlagPage";
import { initGA, logPageView } from "./ga4";
import NewPassword from "./features/auth/NewPassword";
import SuccessPage from "./components/success_page/SuccessPage";
import FailPage from "./components/fail_page/FailPage";
import AvatarSharedManagementPage from "./features/admin_dashboard/load_shered_avatars/AvatarSharedManagementPage";
import AuthAdminRoute from "./features/auth/routes/AuthAdminRoute";
import { DefaultSettingsProvider } from "./default_context/DefaultSettingsContext";
import BlogPage from "./components/blog_page/BlogPage";
import PartnersPage from "./components/partners_page/PartnersPage";
import AddNewPartner from "./features/admin_panel/add_partner/AddNewPartner";
import MetricsPage from "./features/user_synthetic/details_synthetic/statistics/MetricsPage";
import AdminDashboardPage from "./features/admin_dashboard/admin_dashboard/AdminDashboardPage";
import AdminMetricsPage from "./features/admin_dashboard/admin_metrics/AdminMetricsPage";
import EditPage from "./features/edit_page/EditPage";

function App() {
  const auth = useSelector(selectAuth);
  const { isLoading } = auth;

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await checkAuth();
    };

    fetchData();
  }, [auth]);

  if (isLoading) {
    return <PreLoading />;
  }

  return (
    <div>
      <DefaultSettingsProvider>
        <RouteTracker />
        <Routes>
          <Route path="admin" element={<AuthAdminRoute />}>
            <Route index element={<AdminDashboardPage />} />
            <Route path="avatar-management" element={<AvatarSharedManagementPage />} />
            <Route path="metrics" element={<AdminMetricsPage />} />
            <Route path="addPartner" element={<AddNewPartner />} />
          </Route>

          <Route path="account" element={<AuthRoute />}>
            <Route path="mySynthetic" element={<AccountSynthetic />} />
            <Route path="mySynthetic/:synId" element={<DetailsSynthetic />} />
            <Route path="mySynthetic/editStyle/:synId" element={<EditStyle />} />
            <Route path="mySynthetic/create" element={<CreateSynthetic />} />
            <Route path="mySynthetic/metrics/:synId" element={<MetricsPage />} />
            <Route path="profile/privateDate" element={<PrivateDate />} />
            <Route path="profile/company" element={<CompanyInfo />} />
            <Route path="profile/contact" element={<ContactInfo />} />
            <Route path="payment/pricing" element={<Pricing />} />
            <Route path="payment/history" element={<PaymentHistory />} />
            <Route path="payment/info" element={<PaymentInfo />} />
            <Route path="mySynthetic/edit/:synId" element={<EditPage />} />
          </Route>

          <Route element={<PublicRoute />}>
            <Route path="login" element={<Login />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="reset/:id" element={<NewPassword />} />
            <Route path="recoveryEmail" element={<RecoveryEmail />} />
            <Route path="confirm" element={<Confirmation />} />
          </Route>

          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="faq" element={<FAQPage />} />
            <Route path="contactUs" element={<ContactUs />} />
            <Route path="pricing" element={<PricePage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="partners" element={<PartnersPage />} />
            <Route path="howitwork" element={<WorkPage />} />
            <Route path="testyourlanguage" element={<FlagPage />} />
            <Route path="success" element={<SuccessPage />} />
            <Route path="failure" element={<FailPage />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </DefaultSettingsProvider>
    </div>
  );
}

function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

export default App;
