// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useCallback } from "react";
import { format } from "date-fns";
import { Bar } from "react-chartjs-2";
import AccountNavbar from "../../account_layout/AccountNavbar";
import PeriodSelector from "../../../components/period_selector/PeriodSelector";
import styles from "./adminMetricsPage.module.css";
import Button from "../../../components/button/Button";
import buttonStyle from "../../../components/button/button.module.css";
import { axiosWithAuth } from "../../auth/service/axios.config";
import { useDefaultSettings } from "../../../default_context/DefaultSettingsContext";

const AdminMetricsPage = () => {
  const { openErrorWindow } = useDefaultSettings();
  const [metrics, setMetrics] = useState({
    visitors: 0,
    registrations: 0,
    averageTime: 0,
    videoBotsCreated: 0,
    subscriptionCount: 0,
    countries: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchMetrics = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const formattedStartDate = format(startDate, "yyyy-MM-dd'T'00:00:00");
      const formattedEndDate = format(endDate, "yyyy-MM-dd'T'23:59:59");

      try {
        const internalResponse = await axiosWithAuth.get(
          `/api/v1/metrics/by-period?start=${formattedStartDate}&end=${formattedEndDate}`
        );

        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          registrations: internalResponse.data.registrations || 0,
          videoBotsCreated: internalResponse.data.videoBotsCreated || 0,
          subscriptionCount: internalResponse.data.subscriptionCount || 0,
          countries: internalResponse.data.countries || [],
        }));
      } catch (internalError) {
        if (internalError?.message.includes("Token expired")) {
          openErrorWindow("Your session has expired. Please log in again.", true);
        } else {
          setError("Error loading internal metrics");
        }
      }
      try {
        const googleAnalyticsResponse = await axiosWithAuth.get(
          `/api/v1/metrics/google-analytics?start=${formattedStartDate}&end=${formattedEndDate}`
        );

        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          visitors: googleAnalyticsResponse.data.visitors || 0,
          averageTime: googleAnalyticsResponse.data.averageTime || 0,
        }));
      } catch (googleError) {
        console.error("Error loading Google Analytics metrics:", googleError);
      }
    } catch (err) {
      setError("Error loading metrics");
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, openErrorWindow]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handlePeriodChange = (period) => {
    const now = new Date();
    let start;

    switch (period) {
      case "today":
        start = now;
        break;
      case "week":
        start = new Date(now.setDate(now.getDate() - 7));
        break;
      case "month":
        start = new Date(now.setDate(now.getDate() - 30));
        break;
      case "year":
        start = new Date(now.setDate(now.getDate() - 365));
        break;
      default:
        start = now;
    }

    setStartDate(start);
    setEndDate(new Date());
  };

  const barChartData = {
    labels: metrics.countries?.map((country) => country.name) || [],
    datasets: [
      {
        label: "Subscriptions by Country",
        data: metrics.countries?.map((country) => country.subscriptionCount) || [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  return (
    <div className={styles.mainPage}>
      <AccountNavbar />
      <div className={styles.contentContainer}>
        <h1>Admin Statistics</h1>

        <PeriodSelector onSelect={handlePeriodChange} />

        <div className={styles.dateInputContainer}>
          <label>Start date: </label>
          <input
            type="date"
            value={format(startDate, "yyyy-MM-dd")}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />

          <label>End date: </label>
          <input
            type="date"
            value={format(endDate, "yyyy-MM-dd")}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />

          <Button className={buttonStyle.buttonApply} buttonName="Load statistics" onClick={fetchMetrics} />
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <div className={styles.metricsOverview}>
              <h2>General Metrics</h2>
              <p>Visitors: {metrics.visitors}</p>
              <p>New Registrations: {metrics.registrations}</p>
              <p>Average Time on Site: {metrics.averageTime} minutes</p>
              <p>Video Bots Created: {metrics.videoBotsCreated}</p>
              <p>Subscriptions: {metrics.subscriptionCount}</p>
            </div>

            <div className={styles.chartContainer}>
              <h2>Subscriptions by Country</h2>
              <Bar data={barChartData} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminMetricsPage;
