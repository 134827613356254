import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./editStyle.module.css";
import { selectAllSynthetic } from "../../selectors";
import UpdateSyntheticStyle from "../../update_synthetic_style/UpdateSyntheticStyle";

export default function EditStyle() {
  const navigate = useNavigate();
  const { synId } = useParams();
  const synthetics = useSelector(selectAllSynthetic);
  const [synthetic, setSynthetic] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button2");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (synthetics.length > 0) {
      const foundSynthetic = synthetics.find((syn) => syn.syntheticUuid === synId);
      if (foundSynthetic) {
        setSynthetic(foundSynthetic);
      } else {
        navigate("/account/mySynthetic");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [synthetics, synId, navigate]);

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
            id="backToMyAccountButton"
          >
            <div className={styles.buttonBackContainer}>
              <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
              <div className={styles.buttonBackTextContainer}>
                <span>My Synthetic</span>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/${synId}`, "button1")}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            id="generalButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>General</span>
              </div>
              {hoveredButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>

          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/editStyle/${synId}`, "button2")}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
            id="editStyleButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Edit style</span>
              </div>
              {activeButton === "button2" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button2" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>

      <div className={styles.editStyleCard}>
        {synthetic && <UpdateSyntheticStyle syntheticId={synId} avatar={synthetic.avatar} name={synthetic.name} />}
      </div>
    </div>
  );
}
