// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./popupNotificationSettings.module.css";

const PopupNotificationSettings = ({
  selectedAvatar,
  papyrusOptions,
  onPapyrusSettingsChange,
  isEnabled,
  position,
}) => {
  const [papyrusEnabled, setPapyrusEnabled] = useState(isEnabled);
  const [isPapyrusVisible, setIsPapyrusVisible] = useState(false);
  const isAvatarOnRight = position === "right";

  const handleOptionChange = (field, value) => {
    if (value === "" || Number.isNaN(value)) {
      onPapyrusSettingsChange({ ...papyrusOptions, [field]: "" });
      return;
    }

    if (field === "width") {
      value = Math.max(0, Math.min(300, value));
    } else if (field === "fontSize") {
      value = Math.max(0, value);
    }

    onPapyrusSettingsChange({ ...papyrusOptions, [field]: value });
  };

  useEffect(() => {
    setIsPapyrusVisible(isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    if (!papyrusEnabled) return;

    const intervalId = setInterval(
      () => {
        setIsPapyrusVisible((prevVisible) => !prevVisible);
      },
      isPapyrusVisible ? 10000 : 3000
    );
    return () => clearInterval(intervalId);
  }, [papyrusEnabled, isPapyrusVisible]);

  const papyrusWidth = papyrusOptions.width || 300;
  const robotOffset = papyrusWidth + 20;

  const papyrusStyle = {
    backgroundColor: papyrusOptions.backgroundColor || "burlywood",
    color: papyrusOptions.textColor || "black",
    width: `${papyrusOptions.width || 300}px`,
    height: `${papyrusOptions.height || 60}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: `${papyrusOptions.fontSize || 20}px`,
    overflow: "hidden",
    transformOrigin: isAvatarOnRight ? "right center" : "left center",
    animation: isPapyrusVisible ? `${styles.unfoldPapyrus} 3s forwards` : `${styles.foldPapyrus} 3s forwards`,
    borderRadius: isAvatarOnRight ? "30px 0 0 30px" : "0 30px 30px 0",
  };

  const robotStyle = {
    width: "60px",
    height: "60px",
    position: "absolute",
    zIndex: 999,
    left: isAvatarOnRight ? `calc(100% - ${robotOffset - 60}px)` : `${robotOffset - 60}px`,
    animation: isPapyrusVisible
      ? isAvatarOnRight
        ? `${styles.unfoldAndSlideLeft} 3s forwards`
        : `${styles.unfoldAndSlideRight} 3s forwards`
      : isAvatarOnRight
        ? `${styles.foldAndSlideBackLeft} 3s forwards`
        : `${styles.foldAndSlideBackRight} 3s forwards`,
  };

  return (
    <div className={styles.popupNotificationSettingsContainer}>
      <div className={styles.leftSettings}>
        <h3>
          <u>Popup Notification Options</u>
        </h3>

        <div className={styles.settingsRow}>
          <label className={styles.labelWithInput}>
            <input type="checkbox" checked={!!papyrusEnabled} onChange={() => setPapyrusEnabled(!papyrusEnabled)} />
            Enable
          </label>
        </div>

        {papyrusEnabled && (
          <>
            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Background color:
                <input
                  type="color"
                  value={papyrusOptions.backgroundColor || "#D2B48C"}
                  onChange={(e) => handleOptionChange("backgroundColor", e.target.value)}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Text color:
                <input
                  type="color"
                  value={papyrusOptions.textColor || "#000000"}
                  onChange={(e) => handleOptionChange("textColor", e.target.value)}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Text:
                <input
                  type="text"
                  value={papyrusOptions.text}
                  onChange={(e) => handleOptionChange("text", e.target.value)}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Width (max 300px):
                <input
                  type="number"
                  value={papyrusOptions.width}
                  onChange={(e) => handleOptionChange("width", parseInt(e.target.value, 10))}
                  max={300}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Font size (px):
                <input
                  type="number"
                  value={papyrusOptions.fontSize}
                  onChange={(e) => handleOptionChange("fontSize", parseInt(e.target.value, 10))}
                />
              </label>
            </div>
          </>
        )}
      </div>

      {papyrusEnabled && (
        <div className={`${styles.rightAvatarSimulator} ${isAvatarOnRight ? styles.rightAligned : styles.leftAligned}`}>
          <div className={styles.simulatorContainer}>
            <img src={selectedAvatar} alt="Avatar" className={styles.simulatorAvatar} />
            <div className={styles.papyrusPreview} style={papyrusStyle}>
              <span> {papyrusOptions.text || "I can speak!"} </span>
              <img
                src="https://synthetic-bot-test.s3.eu-north-1.amazonaws.com/info.png"
                alt="Robot"
                style={robotStyle}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PopupNotificationSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  papyrusOptions: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
  }).isRequired,
  onPapyrusSettingsChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  position: PropTypes.string.isRequired,
};

PopupNotificationSettings.defaultProps = {
  isEnabled: true,
};

export default PopupNotificationSettings;
