// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, Tooltip, Legend } from "chart.js";

ChartJS.register(LinearScale, CategoryScale, BarElement, Tooltip, Legend);

const ChartMetrics = ({ metrics }) => {
  const videoStartCount = metrics.filter((metric) => metric.eventType === "videoStart").length;
  const videoEndCount = metrics.filter((metric) => metric.eventType === "videoEnd").length;
  const videoPauseCount = metrics.filter((metric) => metric.eventType === "videoPause").length;

  const totalWatchTime =
    metrics
      .filter((metric) => metric.eventType === "videoEnd" || metric.eventType === "videoPause")
      .reduce((acc, metric) => acc + (metric.data?.watchTime || 0), 0) / 1000; // Transfer to sec

  const averageWatchTime = videoStartCount > 0 ? (totalWatchTime / videoStartCount).toFixed(2) : 0;

  const buttonClickMetrics = metrics
    .filter((metric) => metric.eventType === "singleButton" || metric.eventType.startsWith("doubleButton"))
    .map((metric) => `Click on ${metric.data.button || metric.eventType}`);

  const buttonClickCounts = buttonClickMetrics.reduce((acc, buttonLabel) => {
    acc[buttonLabel] = (acc[buttonLabel] || 0) + 1;
    return acc;
  }, {});

  const filteredData = [
    { label: "Video Start", value: videoStartCount },
    { label: "Video End", value: videoEndCount },
    { label: "Video Pause", value: videoPauseCount },
    ...Object.keys(buttonClickCounts).map((label) => ({ label, value: buttonClickCounts[label] })),
  ].filter((metric) => metric.value > 0);

  const data = {
    labels: filteredData.map((metric) => metric.label),
    datasets: [
      {
        label: "Counts",
        data: filteredData.map((metric) => metric.value),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const options = {
    scales: {
      y: { beginAtZero: true },
    },
  };

  return (
    <div>
      <h3>Overview</h3>
      {filteredData.length > 0 ? (
        <>
          <Bar data={data} options={options} />
          <p>Total watch time: {totalWatchTime} seconds</p>
          <p>Average watch time: {averageWatchTime} seconds</p>
          <p>Amount of views: {videoStartCount}</p>
        </>
      ) : (
        <p>No metrics data available for the selected period.</p>
      )}
    </div>
  );
};

ChartMetrics.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      eventType: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      clientDomain: PropTypes.string.isRequired,
      data: PropTypes.shape({
        watchTime: PropTypes.number,
        videoUrl: PropTypes.string,
        button: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
};

export default ChartMetrics;
