import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useDispatch, useSelector } from "react-redux";
import * as selector from "../selectors";
import {
  addSynthetic,
  loadAllAvatar,
  loadAllLanguage,
  loadAllVoice,
  loadAllSharedAvatars,
} from "../userSyntheticSlice";
import PreLoading from "../../../components/pre_loading/PreLoading";
import { selectUser } from "../../auth/selectors";
import styles from "./createSynthetic.module.css";
import CommonErrorWindow from "../../../components/popup_windows/common_error_window/CommonErrorWindow";
import AdvancedSettings from "./settings/advance_settings/AdvancedSettings";
import { useDefaultSettings } from "../../../default_context/DefaultSettingsContext";
import AlignmentSettings from "./settings/alignment_settings/AlignmentSettings";
import AddAvatar from "./settings/add_avatar_settings/AddAvatar";
import NameSynthetic from "../../../components/name_synthetic/NameSynthetic";
import TextSynthetic from "../../../components/text_synthetic/TextSynthetic";
import ConfirmCreateSynWindow from "../../../components/popup_windows/confirm_create_syn_window/ConfirmCreateSynWindow";

export default function CreateSynthetic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const avatars = useSelector(selector.selectAllAvatar);
  const sharedAvatars = useSelector(selector.selectAllSharedAvatar);
  const languages = useSelector(selector.selectAllLanguage);
  const voices = useSelector(selector.selectAllVoice);
  const isLoadingCreateSyn = useSelector(selector.selectIsLoadingCreateSyn);
  const isError = useSelector(selector.selectIsError);
  const [text, setText] = useState("");
  const [confirmWindowIsActive, setConfirmWindowIsActive] = useState(false);
  const [textChecked, setTextChecked] = useState(false);
  const [buttonPuls, setButtonPuls] = useState("");
  const [activeImage, setActiveImage] = useState(null);
  const {
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    buttonOptions: defaultButtonOptions,
    papyrusOptions: defaultPapyrusOptions,
    syntheticDefaults,
  } = useDefaultSettings();
  const [newSynthetic, setNewSynthetic] = useState({
    ...syntheticDefaults,
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    buttonOptions: defaultButtonOptions,
    papyrusOptions: defaultPapyrusOptions,
  });
  const audioUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public_voices/";
  const [currentFile, setCurrentFile] = useState("");
  const [checkDoneName, setCheckDoneName] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [stateOfWindow, setWindowState] = useState("active");
  const [checkDonePicture, setCheckDonePicture] = useState(null);
  const [activeBtnPicture, setActiveBtnPicture] = useState(null);
  const [checkDoneAlignment, setCheckDoneAlignment] = useState(null);
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const [checkDoneLanguage, setCheckDoneLanguage] = useState(null);
  const [activeBtnLanguage, setActiveBtnLanguage] = useState(null);
  const [showDropdownText, setShowDropdownText] = useState(false);
  const [showDropdownVoice, setShowDropdownVoice] = useState(false);
  const [checkDoneVoice, setCheckDoneVoice] = useState(null);
  const [activeBtnVoice, setActiveBtnVoice] = useState(null);
  const [audioPlay, setAudioPlay] = useState(false);
  const [provider, setProvider] = useState("elevenlabs");
  const [successfulAddSyn, setSuccessfulAddSyn] = useState(false);
  const [lang, setLang] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showWarningAlignment, setShowWarningAlignment] = useState(false);
  const [openErrorWindow, setOpenErrorWindow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //
  function calculateCoinFromAudioDuration() {
    const AVERAGE_READING_SPEED_WORDS_PRE_MINUTE = 115;
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;
    const SECONDS_PRE_MINUTE = 60;
    const SECONDS_IN_COIN = 15;
    const durationInSeconds = wordCount / (AVERAGE_READING_SPEED_WORDS_PRE_MINUTE / SECONDS_PRE_MINUTE);
    const roundDurationInSeconds = Math.round(durationInSeconds);
    if (Math.ceil(roundDurationInSeconds / SECONDS_IN_COIN) === 0) {
      return 1;
    }
    return Math.ceil(roundDurationInSeconds / SECONDS_IN_COIN);
  }
  //
  function checkBalanceBeforeCreate() {
    return calculateCoinFromAudioDuration() <= user.balance;
  }

  function play(id, audio) {
    setAudioPlay(!audioPlay);
    setCurrentFile(audio);
    setButtonPuls(id);
  }

  function onAudioEnded() {
    setAudioPlay(false);
    setButtonPuls("");
  }

  const checkAllDone = () => {
    let allDone = true;

    if (checkDoneAlignment === null || !checkDoneAlignment) {
      setCheckDoneAlignment(false);
      setShowWarningAlignment(true);
      allDone = false;
    }
    if (checkDoneName === null || !checkDoneName) {
      setCheckDoneName(false);
      allDone = false;
    }
    if (checkDoneLanguage === null || !checkDoneLanguage) {
      setCheckDoneLanguage(false);
      allDone = false;
    }
    if (checkDonePicture === null || !checkDonePicture) {
      setCheckDonePicture(false);
      allDone = false;
    }
    if (checkDoneVoice === null || !checkDoneVoice) {
      setCheckDoneVoice(false);
      allDone = false;
    }
    if (text === "" || text !== newSynthetic.text) {
      setTextChecked(true);
      allDone = false;
    }
    return allDone;
  };

  const handleClickNavigate = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const handleEdit = (event) => {
    const { name: key, value } = event.target;
    setNewSynthetic((prevNewSynthetic) => ({
      ...prevNewSynthetic,
      [key]: value,
    }));
  };

  const handleClickBtn = (currSection, buttonId) => {
    if (currSection === "pictureSection") {
      setActiveBtnPicture(buttonId);
    }
    if (currSection === "languageSection") {
      setActiveBtnLanguage(buttonId);
    }
    if (currSection === "voiceSection") {
      setActiveBtnVoice(buttonId);
    }
  };

  function setVoice(providerName, voiceName, voiceId) {
    setCheckDoneVoice(true);
    setProvider(providerName);
    handleClickBtn("voiceSection", `btnVoice${voiceName}`);
    setNewSynthetic({ ...newSynthetic, voiceId });
  }

  const getAvatarById = () => {
    const selectedAvatarData =
      avatars.find((ava) => ava.id === activeImage) || sharedAvatars.find((ava) => ava.id === activeImage);
    return selectedAvatarData.binaryData;
  };

  const handleCreateConfirmWindow = () => {
    if (checkAllDone()) {
      setConfirmWindowIsActive(!confirmWindowIsActive);
    }
  };

  const handleCancel = () => {
    if (checkAllDone()) {
      setWindowState("cancel");
      setConfirmWindowIsActive(!confirmWindowIsActive);

      setNewSynthetic({
        ...syntheticDefaults,
        pulsarColor: defaultPulsarColor,
        pulsarSize: defaultPulsarSize,
        pulsarIntensive: defaultPulsarIntensive,
        buttonOptions: defaultButtonOptions,
        papyrusOptions: defaultPapyrusOptions,
      });

      setText("");
      setCheckDoneName(false);
      setCheckDoneLanguage(false);
      setCheckDoneAlignment(false);
      setCheckDoneVoice(false);
      setCheckDonePicture(false);
      setTextChecked(true);

      document.getElementById("nameInputSynthetic").value = "";

      setShowDropdownLanguage(false);
      setShowDropdownText(false);
      setShowDropdownVoice(false);

      setActiveBtnPicture(null);
      setActiveBtnLanguage(null);
      setActiveBtnVoice(null);
    }
  };

  const handleCreate = useCallback(async () => {
    await dispatch(addSynthetic({ newSynthetic, provider }));
    setSuccessfulAddSyn(true);
  }, [dispatch, newSynthetic, provider]);

  useEffect(() => {
    if (isError) {
      setConfirmWindowIsActive(false);
    }
    if (!isError && successfulAddSyn) {
      navigate("/account/mySynthetic");
    }
  }, [isError, successfulAddSyn, navigate]);

  useEffect(() => {
    dispatch(loadAllAvatar());
    dispatch(loadAllLanguage());
    dispatch(loadAllVoice());
    dispatch(loadAllSharedAvatars());
  }, [dispatch]);

  const [alignment] = useState({
    left: "3",
    right: "none",
    bottom: "5",
    checked: true,
    active: "",
  });

  const handleAlignmentChange = (newAlignment) => {
    setNewSynthetic((prevSynthetic) => ({
      ...prevSynthetic,
      left: newAlignment.left,
      right: newAlignment.right,
      bottom: newAlignment.bottom,
    }));
    setShowWarningAlignment(false);
  };

  const nameCheck = (e) => {
    handleEdit(e);
    if (e.target.value !== "") {
      setCheckDoneName(true);
    } else {
      setCheckDoneName(false);
    }
  };

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClickNavigate("/account/mySynthetic", "buttonBack")}
          >
            <div className={styles.buttonBackContainer}>
              <WestIcon style={{ color: "#1570ef" }} />
              <div className={styles.buttonBackTextContainer}>
                <span>back</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      {isError && <CommonErrorWindow open />}
      {isLoadingCreateSyn ? (
        <PreLoading />
      ) : (
        <div className={styles.syntheticCard}>
          <h1>Creation of a new synthetic</h1>
          <NameSynthetic name={newSynthetic.name} nameCheck={nameCheck} checkDoneName={checkDoneName} />

          <AddAvatar
            checkDonePicture={checkDonePicture}
            setActiveImage={setActiveImage}
            activeImage={activeImage}
            OnClickBtn={handleClickBtn}
            setActiveBtnPicture={setActiveBtnPicture}
            activeBtnPicture={activeBtnPicture}
            setErrorMessage={setErrorMessage}
            setOpenErrorWindow={setOpenErrorWindow}
            setSelectedAvatar={setSelectedAvatar}
            setCheckDonePicture={setCheckDonePicture}
            setNewSynthetic={setNewSynthetic}
            stateOfWindow={stateOfWindow}
            setWindowState={setWindowState}
            getAvatarById={getAvatarById}
          />

          <AlignmentSettings
            onAlignmentChange={handleAlignmentChange}
            defaultAlignment={alignment}
            checkDoneAlignment={checkDoneAlignment}
            setCheckDoneAlignment={setCheckDoneAlignment}
            showWarning={showWarningAlignment}
          />

          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownLanguage"
              type="button"
              onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}
            >
              <span className={styles.textIconInButton}>
                {checkDoneLanguage != null &&
                  (checkDoneLanguage ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                3. Select language
              </span>
              {showDropdownLanguage ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownLanguage && (
              <div className={`${styles.dropLang} ${styles.language}`}>
                {languages
                  ? languages.map((lan) => (
                      <button
                        key={lan.id}
                        className={`${styles.btn} ${activeBtnLanguage === `btnLanguage${lan.name}` ? styles.activeBtnByCreate : ""}`}
                        id={`btnLanguage${lan.name}`}
                        type="button"
                        onClick={() => {
                          if (lan.name === "Finnish") {
                            setLang("fi_");
                          } else {
                            setLang("");
                          }
                          setCheckDoneLanguage(true);
                          handleClickBtn("languageSection", `btnLanguage${lan.name}`);
                          setNewSynthetic({ ...newSynthetic, languageId: lan.id });
                        }}
                      >
                        {lan.name}
                      </button>
                    ))
                  : "Not found languages"}
              </div>
            )}
          </div>
          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownText"
              type="button"
              onClick={() => setShowDropdownText(!showDropdownText)}
            >
              <span className={styles.textIconInButton}>
                {textChecked &&
                  (text === newSynthetic.text && text !== "" ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                4. Add text
              </span>
              {showDropdownText ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownText && (
              <TextSynthetic
                text={text}
                setTextChecked={setTextChecked}
                setNewSynthetic={setNewSynthetic}
                newSynthetic={newSynthetic}
                handleClickBtn={handleClickBtn}
                setText={setText}
              />
            )}
          </div>
          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownVoice"
              type="button"
              onClick={() => setShowDropdownVoice(!showDropdownVoice)}
            >
              <span className={styles.textIconInButton}>
                {checkDoneVoice != null &&
                  (checkDoneVoice ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                5. Select a voice
              </span>
              {showDropdownVoice ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownVoice && (
              <div className={`${styles.voicesElement} ${styles.voice}`}>
                {voices
                  ? voices.map(
                      (v) =>
                        (lang !== "fi_" || v.provider !== "microsoft") && (
                          <React.Fragment key={v.id}>
                            {" "}
                            <button
                              className={`${styles.btn} ${activeBtnVoice === `btnVoice${v.name}` ? styles.activeBtnByCreate : ""}`}
                              id={`btnVoice${v.name}`}
                              type="button"
                              onClick={() => {
                                setVoice(v.provider, v.name, v.id);
                              }}
                            >
                              <RecordVoiceOverIcon />
                              {v.name}
                            </button>
                            <div className={styles.hear}>
                              <div
                                className={`${styles.iconWrapper} ${v.id === buttonPuls && audioPlay ? styles.pulsate : ""}`}
                              >
                                <VolumeUpIcon
                                  className={styles.volumeIcon}
                                  onClick={() => {
                                    play(v.id, `${audioUrl + lang + v.name}.mp3`);
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        )
                    )
                  : "No found voices"}
                {audioPlay && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <audio autoPlay onEnded={onAudioEnded}>
                    <source src={currentFile} type="audio/mpeg" />
                  </audio>
                )}
              </div>
            )}
          </div>

          <AdvancedSettings
            selectedAvatar={selectedAvatar || "/syntheticAvatar.webp"}
            onSaveSettings={(updatedSettings) => {
              setNewSynthetic((prev) => ({ ...prev, ...updatedSettings }));
            }}
          />

          <CommonErrorWindow
            open={openErrorWindow}
            onClose={() => setOpenErrorWindow(false)}
            text={errorMessage}
            redirectToLogin={false}
          />

          <button
            className={`${styles.btn} ${styles.createBtnActive}`}
            id="createBtn"
            type="button"
            onClick={handleCreateConfirmWindow}
          >
            Create
          </button>
          {confirmWindowIsActive && (
            <ConfirmCreateSynWindow
              text={text}
              newSynthetic={newSynthetic}
              setConfirmWindowIsActive={setConfirmWindowIsActive}
              handleCancel={handleCancel}
              handleCreate={handleCreate}
              getAvatarById={getAvatarById}
              handleCreateConfirmWindow={handleCreateConfirmWindow}
            />
          )}
        </div>
      )}
    </div>
  );
}
