// eslint-disable-next-line no-unused-vars
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import styles from "../../../createSynthetic.module.css";

const AvatarSelectorModal = ({
  title,
  avatars,
  activeImage,
  setActiveImage,
  handleSelectImage,
  handleDeleteAvatar,
  closeModal,
}) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h1>{title}</h1>
        <div className={styles.avatarBox}>
          {avatars.length !== 0
            ? avatars.map((ava) => (
                <div className={styles.avatarCard} key={ava.id}>
                  <button
                    className={`${styles.selectAvatar} ${activeImage === ava.id ? styles.activeImage : ""}`}
                    id={`selectBtn${ava.id}`}
                    type="button"
                    onClick={() => setActiveImage(ava.id)}
                    aria-label={`Select avatar ${ava.name}`}
                  >
                    <img src={`data:image/*;base64,${ava.binaryData}`} alt={ava.name} />
                  </button>
                  {ava.isDeletable && handleDeleteAvatar && (
                    <button
                      className={styles.deleteAvatarButton}
                      onClick={() => handleDeleteAvatar(ava.id)}
                      type="button"
                      aria-label={`Delete avatar ${ava.name}`}
                    >
                      <DeleteIcon style={{ color: "#EC4A0A" }} />
                    </button>
                  )}
                </div>
              ))
            : "No avatars uploaded yet"}
        </div>

        <p className={styles.buttonLineElement}>
          <button
            className={`${styles.btn} ${styles.modalSelectBtn}`}
            type="button"
            id="modalSelectBtn"
            onClick={handleSelectImage}
            disabled={!activeImage}
          >
            Select
          </button>

          <button
            className={`${styles.btn} ${styles.modalCancelBtn}`}
            type="button"
            id="modalUploadCancelBtn"
            onClick={closeModal}
          >
            Cancel
          </button>
        </p>
      </div>
    </div>
  );
};

AvatarSelectorModal.propTypes = {
  title: PropTypes.string.isRequired,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      binaryData: PropTypes.string.isRequired,
      isDeletable: PropTypes.bool,
    })
  ).isRequired,
  activeImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveImage: PropTypes.func.isRequired,
  handleSelectImage: PropTypes.func.isRequired,
  handleDeleteAvatar: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
};

AvatarSelectorModal.defaultProps = {
  activeImage: null,
  handleDeleteAvatar: null,
};

export default AvatarSelectorModal;
