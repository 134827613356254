// eslint-disable-next-line no-unused-vars
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import buttonStyle from "../../../components/button/button.module.css";
import styles from "./adminDashboard.module.css";

const AdminDashboardPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.dashboardContainer}>
      <h1>Admin Dashboard</h1>

      <div className={styles.buttonsContainer}>
        <Button
          className={buttonStyle.buttonAdmin}
          buttonName="Avatar Management"
          onClick={() => handleNavigate("/admin/avatar-management")}
        />
        <Button
          className={buttonStyle.buttonAdmin}
          buttonName="Metrics"
          onClick={() => handleNavigate("/admin/metrics")}
        />
      </div>
    </div>
  );
};

export default AdminDashboardPage;
