import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

export default function AuthAdminRoute() {
  const adminToken = Cookies.get("adminToken");

  if (!adminToken) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
